import axios from 'axios'
import { message } from 'ant-design-vue'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  // baseURL: 'http://192.168.3.73:8085',
  // baseURL: 'https://wrdy.ms-cloud.cn/api',
  timeout: 8000
})

// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
service.setToken = (token) => {
  service.defaults.headers.Authorization = token
  localStorage.setItem('token', token)
}

// 获取token
function getToken() {
  const token = localStorage.getItem('token')
  return token
}

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers.Authorization = 'Bearer ' + getToken() // 让每个请求携带自定义token
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 路由白名单（没有返回code）
const whiteList = ['/user/export']

// response 拦截器
service.interceptors.response.use(
  (response) => {
    const code = response.data.code
    if (code === 401) {
      // token失效返回登录
      message.warning('登录状态已过期，请重新登录')
      localStorage.removeItem('token')
      store.dispatch('setUserInfo', {})
      store.dispatch('setNoticeCount', 0)
      router.push('/')
    } else if (code !== 200) {
      // 判断是否是导出的接口
      if (whiteList.indexOf(response.config.url) !== -1) {
        return response
      } else {
        if (response.data.message) {
          message.error(response.data.message)
        }
        return Promise.reject(new Error())
      }
    } else {
      return response
    }
  },
  (error) => {
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        message.error('网络请求超时')
        return Promise.reject(error)
      }
      if (error.toString().indexOf('Error: Network Error') !== -1) {
        message.error('网络请求错误')
        return Promise.reject(error)
      }
    }
    if (code === 504) {
      message.error('服务器异常')
      return Promise.reject(error)
    }
    if (code === 401) {
      message.warning('登录状态已过期，请重新登录')
      localStorage.removeItem('token')
      router.push('/')
      location.reload()
    } else if (code === 403) {
      message.warning('资源不可用')
    } else {
      const errorMsg = error.response.data.message
      if (errorMsg !== undefined) {
        message.error(errorMsg)
      }
    }
    return Promise.reject(error)
  }
)

export default service
