<template>
  <div class="pagination">
    <a-pagination
      :current="current"
      :page-size-options="pageSizeOptions"
      :total="total"
      show-size-changer
      :page-size="pageSize"
      :show-total="total => `共${total}条，${Math.ceil(total / pageSize)}页`"
      @change="pageChange"
      @showSizeChange="pageChange"
    />
  </div>
</template>

<script>
import { Pagination } from 'ant-design-vue'
export default {
  components: {
    APagination: Pagination
  },
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 50
    },
    pageSize: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      pageSizeOptions: ['10', '20']
    }
  },
  methods: {
    pageChange(current, size) {
      this.$emit('change', current, size)
    }
  }
}
</script>

<style scoped>
.pagination {
  background-color: #ffffff;
  text-align: right;
  margin: 20px 12px 20px 0;
}
.pagination >>> .ant-pagination-item-active a {
  color: #4961cd;
}
.pagination >>> .ant-pagination-item-active {
  border-color: #4961cd;
}
.pagination >>> .ant-pagination-item:hover {
  color: #4961cd;
}
</style>
