import request from '@/utils/request'
import service from '@/utils/req'
// 获取用户管理列表
export function user(data) {
  return request.get('/user', data)
}
// 获取党支部下拉数据
export function branch(data) {
  return request.get('/user/branch', data)
}
// 用户单点查询
export function userid(id) {
  return request.get(`/user/${id}`)
}
// 删除用户管理信息
// 删除参数列表
export function deluser(params) {
  return request.delete('/user', { params })
}
// 修改用户
export function userupdate(data) {
  return request.post('/user/update', data)
}
// 导入模板
export function uploadImg(data) {
  return request.post('/user/importExcel', data)
}
// 导出信息
export function userexport(params) {
  return service({
    url: '/user/export',
    method: 'get',
    params,
    responseType: 'blob',
    timeout: 30000
  })
}

// 获取单位
export function getDeptList(data) {
  return request.get('/dept/getDeptList', data)
}
// 设置管理员
export function setAdmin(data) {
  return request.post('/user/setAdmin', data)
}
// 审核用户
export function locked(data){
  return request.get('/user/locked',data)
}