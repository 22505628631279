<template>
  <div class="container">
    <div class="head">
      <div class="header_left">
        <a-form-model class="head_form" :mode="userForm">
          <a-form-model-item label="姓名">
            <a-input placeholder="请输入姓名" v-model="userForm.realName" :maxLength="20" />
          </a-form-model-item>
          <a-form-model-item label="手机号">
            <a-input placeholder="请输入手机号" v-model="userForm.tel" :maxLength="11" />
          </a-form-model-item>
          <a-form-model-item label="教职工号">
            <a-input placeholder="请输入教职工号" v-model="userForm.staff" :maxLength="20" />
          </a-form-model-item>
        </a-form-model>
        <div class="header_btn">
          <a-button class="search" @click="search">查询</a-button>
          <a-button class="reset" @click="reset">重置</a-button>
        </div>
      </div>
      <div class="header_right">
        <a href="/static/党员评价堡垒指数扣分模版.xlsx" download>下载模板</a>
        <a-upload :beforeUpload="file" :customRequest="uploadfile" :file-list="exeFileList" @change="exeChange">
          <div class="leadin">
            <img src="../../../../assets/images/leadin.png" alt="" />
            <div class="leadin_title">导入扣分清单</div>
          </div>
        </a-upload>

        <div class="leadout" @click="leadout">
          <img src="../../../../assets/images/leadout.png" alt="" />
          <div class="leadout_title">导出清单</div>
        </div>
      </div>
    </div>
    <div class="content">
      <a-table
        style="display: flex;width: 100%"
        :scroll="{ x: columns.length * 150, y: '100%-5px' }"
        rowKey="id"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :row-selection="rowSelection"
      >
        <!-- 序号 -->
        <span slot="(index,text,record)" slot-scope="index, record, text">{{ text }}</span>
        <!-- 姓名 -->
        <span slot="userName" slot-scope="text, records">
          <img class="avatar" :src="records.avatar" alt="" />
          <span>{{ records.realName }}</span>
        </span>
        <!-- 所属党支部 -->
        <span slot="deptName" slot-scope="text, records">{{ records.echoMap.deptName }}</span>
        <!-- 学习星 -->
        <span slot="study" slot-scope="records, text">{{ text.echoMap.userIntegration ? text.echoMap.userIntegration.study.toFixed(1) : '0.0' }}</span>
        <!-- 服务星 -->
        <span slot="service" slot-scope="records, text">{{ text.echoMap.userIntegration ? text.echoMap.userIntegration.service.toFixed(1) : '0.0' }}</span>
        <!-- 工作星 -->
        <span slot="work" slot-scope="records, text">{{ text.echoMap.userIntegration ? text.echoMap.userIntegration.work.toFixed(1) : '0.0' }}</span>
        <!-- 廉洁星 -->
        <span slot="honest" slot-scope="records, text">{{ text.echoMap.userIntegration ? text.echoMap.userIntegration.honest.toFixed(1) : '0.0' }}</span>
        <!-- 道德星 -->
        <span slot="morality" slot-scope="records, text">{{ text.echoMap.userIntegration ? text.echoMap.userIntegration.morality.toFixed(1) : '0.0' }}</span>
        <!-- 管理员 -->
        <span slot="type" slot-scope="records, text">
          <a-switch :checked="Boolean(records)" @change="userStateChanged(text)" />
        </span>
        <!-- 审核状态 -->
        <span slot="locked" slot-scope="records, text">
          <a-switch :checked="!Boolean(records)" @change="userAuditChanged(text)" />
        </span>
        <span class="action" slot="action" slot-scope="text, records">
          <span class="edit" @click="showUser(records.id)">编辑</span>
          <a-popconfirm title="是否删除该信息？" ok-text="确认" cancel-text="取消" @confirm="yes(records.id)" @cancel="no">
            <span class="del">删除</span>
          </a-popconfirm>
        </span>
      </a-table>
      <Pagination :current="current" :page-size="size" :total="total" @change="change" />
    </div>
    <!-- 修改弹框 -->
    <a-modal v-model="visible" title="编辑" @ok="handleOk" @cancel="resetCancel" footer="" centered>
      <div class="main">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <!--账户名称-->
          <a-form-model-item class="formitem" label="姓名" prop="realName">
            <a-input v-model="form.realName" placeholder="请输入姓名" />
          </a-form-model-item>
          <a-form-model-item class="formitem" label="手机号" prop="tel">
            <a-input placeholder="请输入手机号" v-model="form.tel" disabled />
          </a-form-model-item>
          <a-form-model-item class="formitem" label="教职工号" prop="staff">
            <a-input v-model="form.staff" placeholder="请输入教职工号" />
          </a-form-model-item>
          <a-form-model-item class="formitem" label="所属党支部" prop="partyBranch">
            <a-select v-model="form.partyBranch" placeholder="请选择所属党支部">
              <a-select-option v-for="item in branchList" :key="item.id" :value="item.id">
                {{ item.deptName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item class="formitem" label="设为管理员" prop="type">
            <a-switch :checked="Boolean(form.type)" />
          </a-form-model-item> -->
          <div class="footer">
            <a-button class="editsave" @click="editsave">确定</a-button>
            <a-button class="editcancel" @click="editcancel">取消</a-button>
          </div>
        </a-form-model>
      </div>
    </a-modal>
    <!--导出弹框 -->
    <a-modal v-model="leadoutVisible" title="导出清单" @ok="handleOk" footer="" centered>
      <div class="main">
        <a-form-item>
          <a-form-model-item label="导出内容">
            <a-radio-group name="radioGroup" @change="radioChange">
              <div>
                <a-radio :value="1">学习星</a-radio>
                <a-radio :value="2">服务星</a-radio>
                <a-radio :value="3">工作星</a-radio>
              </div>
              <a-radio :value="4">廉洁星</a-radio>
              <a-radio :value="5">道德星</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-form-item>
      </div>
      <div class="leadout_bottom">
        <span>注意：清单导出默认按分数从高到低排列</span>
        <div class="leadout_btn">
          <a-button class="leadout_cancel" @click="exportCancel">取消</a-button>
          <a-button @click="exportData" class="leadout_start">{{ exportLoading ? '导出中...' : '开始导出' }}</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { phoneCheck, checkCourse } from '@/utils/validator'
import { uploadImg } from '@/api/file'
import { user, getDeptList, userid, deluser, userupdate, userexport, setAdmin, locked } from '@/api/user'
import Pagination from '@/components/pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      exeFileList: [],
      leadoutVisible: false,
      visible: false,
      exportLoading: false,
      selectedRowKeys: [],
      data: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 80,
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          width: 160,
          scopedSlots: { customRender: 'userName' }
        },
        {
          title: '教职工号',
          dataIndex: 'staff',
          width: 150
        },
        {
          title: '所属党支部',
          dataIndex: 'deptName',
          width: 160,
          scopedSlots: { customRender: 'deptName' }
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          width: 150
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          width: 160
        },
        {
          title: '学习星',
          dataIndex: 'study',
          width: 100,
          scopedSlots: { customRender: 'study' }
        },
        {
          title: '服务星',
          dataIndex: 'service',
          width: 100,
          scopedSlots: { customRender: 'service' }
        },
        {
          title: '工作星',
          dataIndex: 'work',
          width: 100,
          scopedSlots: { customRender: 'work' }
        },
        {
          title: '廉洁星',
          dataIndex: 'honest',
          width: 100,
          scopedSlots: { customRender: 'honest' }
        },
        {
          title: '道德星',
          dataIndex: 'morality',
          width: 100,
          scopedSlots: { customRender: 'morality' }
        },
        {
          title: '设为管理员',
          dataIndex: 'type',
          width: 100,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '审核状态',
          dataIndex: 'locked',
          width: 90,
          fixed: 'right',
          scopedSlots: { customRender: 'locked' }
        },
        {
          title: '操作',
          key: 'action',
          width: 140,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      size: 10,
      total: 0,
      current: 1,
      userForm: {
        realName: '',
        tel: '',
        staff: ''
      },
      form: {},
      // 党支部
      branchList: [],
      rules: {
        realName: [{ required: true, message: '请输入姓名' }],
        tel: [{ required: true, message: '请输入手机号' }, { validator: phoneCheck }],
        staff: [{ required: true, message: '请输入教职工号' }, { validator: checkCourse }],
        partyBranch: [{ required: true, message: '请选择党支部信息' }]
      },
      typeid: 0
    }
  },
  created() {
    this.getUserList()
    this.getBranchList()
  },
  methods: {
    // 获取用户列表
    async getUserList() {
      const data = await user({
        realName: this.userForm.realName,
        tel: this.userForm.tel,
        staff: this.userForm.staff,
        current: this.current,
        size: this.size
      })
      if (data.code === 200) {
        this.data = data.data.records
        this.total = data.data.total
      }
      console.log(this.data, '12')
    },
    // 获取党支部列表数据
    async getBranchList() {
      const data = await getDeptList()
      if (data.code === 200) {
        this.branchList = data.data
        console.log(this.branchList, 'bran')
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    // 分页
    change(page, size) {
      this.current = page
      this.size = size
      this.getUserList()
    },
    // 设为管理员
    async userStateChanged(e) {
      const formData = new FormData()
      formData.append('id', e.id)
      const data = await setAdmin(formData)
      if (data.code === 200) {
        this.getUserList()
        this.$message.success('状态变换成功!')
      }
    },
    // 审核
    async userAuditChanged(e) {
      const data = await locked({ id: e.id })
      if (data.code === 200) {
        if (data.data === 0) {
          this.getUserList()
          this.$message.success('审批已通过!')
        } else if (data.data === 1) {
          this.getUserList()
          this.$message.success('审批不通过!')
        }
      }
    },
    // 查询
    search() {
      this.getUserList()
    },
    // 重置
    reset() {
      this.userForm = {}
      this.getUserList()
    },
    async showUser(id) {
      const data = await userid(id)
      this.form = data.data
      console.log(this.form, 'form111111')
      this.visible = true
    },
    editcancel() {
      this.visible = false
      this.$refs.ruleForm.resetFields()
    },
    resetCancel() {
      this.visible = false
      this.$refs.ruleForm.resetFields()
    },
    editsave() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const data = await userupdate({ ...this.form })
          if (data.code === 200) {
            this.$message.success('编辑成功!')
            this.visible = false
            this.getUserList()
          }
        }
      })
    },
    handleOk() {},
    // 删除
    async yes(id) {
      const data = await deluser({ userId: id })
      console.log(data)
      this.$message.success('删除成功!')
      this.getUserList()
    },
    no() {},
    // 导出
    async leadout() {
      this.leadoutVisible = true
    },
    exportCancel() {
      this.leadoutVisible = false
    },
    // 审核
    async audit(id) {
      const data = await locked({ id: id })
      console.log(data, '1')
    },
    // 数据导出
    exportData() {
      const params = {
        realName: '',
        tel: '',
        staff: '',
        typeId: this.typeid
      }
      this.exportLoading = true
      console.log(userexport)
      userexport(params)
        .then((res) => {
          console.log(res, 'res')
          this.exportLoading = false
          const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
          const fileName = '用户管理列表.xlsx'
          const linkNode = document.createElement('a')
          linkNode.download = fileName // a标签的download属性规定下载文件的名称
          linkNode.style.display = 'none'
          linkNode.href = URL.createObjectURL(blob) // 生成一个Blob URL
          document.body.appendChild(linkNode)
          linkNode.click() // 模拟在按钮上的一次鼠标单击
          URL.revokeObjectURL(linkNode.href) // 释放URL 对象
          document.body.removeChild(linkNode)
          this.leadoutVisible = false
        })
        .catch(() => {
          this.exportLoading = false
          console.log('1111111111112')
        })
    },
    exeChange() {},
    // 文件上传
    file(file) {
      const _this = this
      return new Promise((resolve, reject) => {
        console.log(file)
        // console.log(_this.pdfFileList, 'pdflist')
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          resolve(file)
        } else {
          reject(file)
        }
      })
    },
    async uploadfile(file) {
      const formData = new FormData()
      formData.append('simpleFile', file.file)
      const data = await uploadImg(formData)
      console.log(data, '1111111111111111111111111111')
      if (data.code === 200) {
        this.$message.success('导入成功'), this.getUserList()
      }
      console.log(data, 'asdasdasdasd')
    },
    radioChange(e) {
      this.typeid = e.target.value
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        Selections: [
          {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            }
          },
          {
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows)
            }
          },
          {
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows)
            }
          }
        ],
        onSelection: this.onSelection
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  background: #f8f9fe;
}

.head {
  background: #ffffff;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.header_left {
  display: flex;
  align-items: center;
}

.header_right {
  display: flex;
  align-items: center;
  color: #f44600;
  line-height: 13px;
  cursor: pointer;
}
.header_right > a {
  color: #f44600;
}

* >>> .ant-input {
  width: 200px;
  height: 36px;
}

.head_form {
  display: flex;
  align-items: center;
}

.head_form >>> .ant-form-item {
  display: flex;
}

* >>> .ant-form-item {
  margin: 0;
  margin-right: 14px;
}

.search {
  width: 68px;
  height: 36px;
  background: #f44600;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 0 14px 0 6px;
}

.reset {
  width: 68px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
}

.leadout {
  display: flex;
  padding-left: 15px;
  align-items: center;
}
.leadin {
  display: flex;
  align-items: center;
  padding-left: 15px;
}
.content {
  margin-top: 20px;
  background: #fff;
  height: calc(100vh - 196px);
  display: flex;
  flex-direction: column;
}

.content >>> .ant-table-wrapper {
  overflow-y: auto;
  flex: 1;
}

.avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}

.edit,
.auditing,
.del {
  font-size: 14px;
  color: #f44600;
  margin-right: 20px;
  cursor: pointer;
}

.content >>> .ant-spin-nested-loading {
  width: 100%;
}

* >>> .ant-table-thead > tr > th {
  background: #f7f8f9 !important;
}

* >>> .ant-table-tbody > tr > td {
  background: #fff !important;
}

* >>> .ant-modal-title {
  font-weight: bold;
  font-size: 16px;
  color: #353535;
}

.editsave {
  background: #f44600;
  color: #fff;
  width: 68px;
  height: 36px;
  border-radius: 2px;
  margin-right: 40px;
}

.editcancel {
  width: 68px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main >>> .ant-form-item {
  display: flex;
  margin-bottom: 16px;
}

.formitem >>> .ant-input {
  width: 300px;
  height: 42px;
}

.formitem >>> .ant-form-item-label {
  width: 100px;
  text-align: right;
}

.formitem >>> .ant-select-selection--single {
  width: 300px;
  height: 42px;
}

.formitem >>> .ant-select-selection__rendered {
  height: 42px;
  display: flex;
  align-items: center;
}

.main {
  display: flex;
  justify-content: center;
}

.main >>> .ant-form-item-control {
  padding: 0 20px;
}

.main >>> .ant-radio-wrapper {
  padding: 8px;
}

.leadout_cancel {
  width: 98px;
  height: 36px;
  background: #fbfbfb;
  border-radius: 2px;
  border: 1px solid #e4e8eb;
  color: #353535;
  margin-right: 40px;
}

.leadout_start {
  width: 98px;
  height: 36px;
  background: #f44600;
  border-radius: 2px;
  color: #ffffff;
}

.leadout_bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.leadout_btn {
  margin-top: 20px;
}

.leadin_title {
  color: #f44600;
}
</style>
